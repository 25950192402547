/* Chat Container Styles */
.chatui {
  position: absolute;
  top: 200px;
  right: 150px;
  width: 600px;
}

/* Main Container Styles */
.cs-main-container {
  border: none;
  border-radius: 10px;
  background-color: #181818;
}

/* Message List Styles */
.cs-message-list {
  background-color: #181818;
}

/* Message Content Styles */
.cs-message__content {
  margin-top: 15px;
  background-color: #242424;
  color: rgb(245, 242, 242);
}

.cs-message--outgoing .cs-message__content {
  color: rgba(255, 255, 255, 0.87);
  background-color: #5E5EE1;
  border-radius: 0.7em;
}

.cs-message--incoming .cs-message__content {
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  border-radius: 0.7em;
}

/* Chat Container Styles */
.cs-chat-container {
  background-color: #242424;
}

/* Message Input Styles */
.cs-chat-container .cs-message-input {
  background-color: #777474;
  border: none;
}

/* Content Editor Wrapper Styles */
.cs-message-input__content-editor-wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: #adacac;
  margin: 0;
  margin-left: 10px;
  padding: 0.6em 0.9em;
  border-radius: 0.7em;
  flex-grow: 1;
}

/* Placeholder Styles */
.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: rgba(255, 255, 255, 0.7);
}

/* Content Editor Styles */
.cs-message-input__content-editor {
  background-color: #adacac;
  color: white;
  font-weight: 400;
}

/* Attachment Button Styles */
.cs-button--attachment {
  display: none;
}

/* Send Button Styles */
.cs-button--send {
  color: #ffffff;
  background-color: transparent;
}

.cs-button--send::after {
  content: "发送";
  margin-left: 5px;
}

/* Typing Indicator Styles */
.cs-message-list .cs-typing-indicator {
  background-color: #181818;
  padding: 0.5em 0.5em;
  font-size: 1em;
  height: 1.25em;
  line-height: 1.25em;
}

/* Button Container Styles */
.button-container {
  display: flex;
  justify-content: flex-end;
}

/* API Button Styles */
.api-button {
  padding: 0.5rem 1rem;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.api-button.med {
  background: #1D2CF3;
}

.api-button.dental {
  background: #38c68b;
}

.api-button.pet {
  background: #E43292;
}

/* Media Queries */

@media (max-width: 1440px) {
  /* Styles for MacBook */
  .chatui {
    top: 155px;
    right: 100px;
    width: 600px;
  }
}

@media (max-width: 1299px) {
  /* Styles for iPad */
  .chatui {
    top: 100px;
    right: 100px;
    width: 500px;
  }
  .chatComponent{
    height: 380px !important;
  }

}

@media (max-width: 1000px) {
  /* Styles for iPad mini */
  .chatui {
    position: absolute;
    top: 100px;
    left: 40px;
    width: 90%;
  }
  
  /* Center Button Container on iPhone */
  .button-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  /* Styles for phone */
  .chatui {
    position: absolute;
    top: 80px;
    left: 18px;
    width: 90%;
  }
}

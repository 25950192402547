
 .App {
  position: relative;
  z-index: 1;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 100px; /* Ensure space for the footer */
}

.footer {
  position: fixed;
  bottom: 0;
  /* width: 100%; */
}

@media (max-width: 1000px) {
  .footer {
    position: absolute;
  }
}
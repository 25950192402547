body {
  margin: 0 ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-bg: #2a2d31;
}

.area{
    background: #21211D;  
    background: -webkit-linear-gradient(to left, #505165, #21211D);  
    width: 100%;
    height:100%;
    z-index: -1;
    position: fixed
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: animate 25s linear infinite;
            animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
}



@-webkit-keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
/* Overlay Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(39, 40, 53, 0.8);
  -webkit-backdrop-filter: blur(0.3px);
          backdrop-filter: blur(0.3px);
  z-index: 998;
}

/* Popup Container Styles */
.popup {
  box-sizing: border-box;
  position: fixed;
  width: 450px;
  height: 470px;
  left: calc(50% - 420px / 2);
  top: calc(50% - 470px / 2);
  z-index: 999;
}

/* Popup Content Styles */
.popup-content {
  background-color: #191a23;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Active Popup Styles (If needed) */
.popup.active {
  display: flex;
}

/* Typography */
h2 {
  text-align: center;
  font-size: 30px;
}

/* Form Input Styles */
label {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #272835;
  background-color: #191a23;
  border-radius: 3px;
  color: white;
  outline: 0;
}

/* Hover Styles for Input and Textarea */
input:hover,
textarea:hover {
  border-color: #4e497d;
}

/* Submit Button Styles */
.submit_button {
  background-color: #4e497d;
  color: #181819;
  font-size: 15px;
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

/* Close Button Styles */
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #7e77c3;
  background: rgb(26, 26, 34);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 18px;
}

/* Media Query for Smaller Screens (iPhone) */
@media (max-width: 425px) {
  .popup {
    width: 320px;
    height: 470px;
    left: 50%;
    top: calc(50% - 500px/2);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 999;
  }
}

.error-notification {
    background-color: #FF4136; /* Red background for error messages */
    color: white; /* Text color for error messages */
    padding: 12px 16px; /* Padding around the content */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Margin at the bottom */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    transition: opacity 0.3s ease-in-out;
    opacity: 0.95;
  }
  
  .success-notification {
    background-color: #2ECC40; /* Green background for success messages */
    color: white; /* Text color for success messages */
    padding: 12px 16px; /* Padding around the content */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Margin at the bottom */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    transition: opacity 0.3s ease-in-out;
    opacity: 0.95;
  }
  
/* Base Styles */
.hero-container {
  padding: 1.5rem;
  color: white;
}

.hero-text {
  margin-bottom: 30px;
}

.hero-text h1 {
  padding-top: 60px;
  font-size: 45px;
}

.subheading {
  margin-bottom: -10px;
  font-size: 25px;
}

.text{
  margin-top: 20px !important;
}
.example {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.hero-button {
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffffff;
  margin-top: 25px;
  background: none;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 1440px) {
  /* styles for macbook */
  .hero-text h1 {
    padding-top: 15px;
    font-size: 35px;
  }
}



@media (max-width: 1299px) {
  /* styles for ipad */
  .hero-container {
    margin-top: -40px;
  }
  .hero-text h1 {
    font-size: 32px;
  }
  .subheading {
    font-size: 18px;
  }

  .hero-examples {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  /* styles for iphone */
  .hero-container {
    margin-top: 430px;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center; /* Center text within the container */
  }

  .hero-text h1 {
    font-size: 32px;
  }
  .subheading {
    font-size: 18px;
  }

  .hero-examples {
    font-size: 14px;
  }

  .hero-button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  /* Styles for phone */
  .hero-text h1 {
    font-size: 22px;
  }

  .subheading {
    font-size: 14px;
  }

  .hero-examples {
    font-size: 13px;
  }
}

/* Base Styles */
.navbar {
  display: flex;
  color: white;
  z-index: 2;
}

.navbar-container {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; 
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
}

.logo-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.nav-button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #7E77C3;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 25px;
}

.button-container {
  display: flex; 
  align-items: center;
}

/* Styling for the dropdown container */
.dropdown-container {
  position: relative;
  display: inline-block;
  display: none;
}

/* Styling for the dropdown button */
.dropdown-button {
  background-color: #7E77C3;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

/* Styling for the dropdown box */
.dropdown-box {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #191A23;
  border: 2px solid #272835;
  width: 150px;
  height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none;
  z-index: 999;
}

/* Styling for individual rows */
.dropdown-box span {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

/* Center-align the button */
.dropdown-box button.nav-button {
  display: block;
  margin: 0 auto;
}

/* Styling for the dropdown box content */
.dropdown-box span.user-name {
  font-weight: bold;
}

.nav-button.dropdown-button {
  background-color: transparent;
}

.nav-button.dropdown-button span img {
  margin-right: 10px;
}

.nav-button.dropdown-button span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Show the dropdown content when the dropdown button is clicked */
.dropdown-container:hover .dropdown-box {
  display: block;
}

.nav-button.signout{
  margin-top: 15px !important;
  font-size: 11px;
  padding: 2px 8px;
}


@media (max-width: 700px) {
  /* styles for smaller screens (e.g., mobile) */
    .navbar-container {
      padding: 1rem;
  }
  .logo-container img {
    width: 70px;
  }

  .logo-container h3 {
    font-size: 12px;
  }

  .nav-button {
    font-size: 12px;
    padding: 2px 10px;
  }
}


@media (max-width: 425px) {
  .nav-button {
    font-size: 10px;
    padding: 1px 4px;
  }
  .navbar-container {
    grid-gap: 1rem;
    gap: 1rem;
    }
  .profile{
    width: 20px;
  }
  .nav-button.signout{
    margin-top: 8px !important;
    font-size: 8px;
    padding: 0.5px 3px;
  }
  .dropdown-box {
    width: 80px;
    height: 50px;
  }
  .dropdown-box span {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
  }
}
/* Base Styles */
.popup {
  box-sizing: border-box;
  position: fixed;
  top: calc(50% - 520px/2);
  z-index: 999;
}

.popup-content {
  color: white;
  background-color: #191A23;
  border: 2px solid #272835;
}

select {
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  margin-left: 5px;
}


/* Form Elements */
.react-tel-input .form-control {
  margin-top: 0 !important;
  height: 40px !important;
  width: 100% !important;
}

/* Verification Code Input */
.verifi_text {
  margin-top: 10px;
}

.code-input {
  display: flex;
  align-items: center;
}

.veri_button {
  background-color: #4e497d;
  color: #fefeff;
  font-size: 15px;
  border: none;
  padding: 8px 28px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  white-space: nowrap;
  margin-bottom: 17px;
}

/* Submit Button */
.submit_button {
  background-color: #4e497d;
  color: #fefeff;
  font-weight: bold;
  font-size: 18px;
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Password Toggle Button */
.password-toggle {
  position: absolute;
  background: none;
  border: none;
  top: 218px;
  right: 25px;
}

.open_eye, .closed_eye {
  width: 20px;
  fill: white;
}

/* Switch Button */
.switch-button {
  background: none;
  border: none;
  color: #7E77C3;
  cursor: pointer;
  margin-bottom: 6px;
}

/* Checkbox Container */
.checkbox-container {
  font-size: 13.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-container a {
  color: white;
}

/* Country Code Select */
.country-list {
  color: rgb(255, 255, 255);
}

#countryCode {
  font-size: 15px;
  position: absolute;
  top: 143px;
  padding-left: 5px;
  left: 23px;
  border: none;
  background-color: #191A23;
  color: white;
}

.captchaimage{
  margin-left: 10px;
  border-radius: 10px;
  width: 90px;
  margin-bottom: 15px;
}

.refresh_btn{
  margin-bottom: 15px;
  margin-left: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.refresh{
 width: 10px;
 height: 10px;
}
/* Custom Input */
.custom-input {
  /* padding-left: 57px; */
  width: 95%;
  background-color: #191A23;
  border: 1px solid #272835;
  color: white;
}

/* Error Message */
.error-message {
  font-size: 13px;
  color: #f33636;
}

.grayed-button {
  background-color: gray; /* Change the background color to gray */
  color: white; /* Change the text color for better visibility */
  cursor: not-allowed; /* Change the cursor style to indicate it's disabled */
}


/* Responsive Styles */
/* @media (max-width: 425px) {
  .popup {
    top: calc(50% - 300px/2);
    z-index: 999;
    margin-left: 12px;
  }

  #countryCode {
    top: 140px;
  }
  
  .custom-input {
    width: 94%;
  }
} */


/* Chat Container Styles */
.chatui {
  position: absolute;
  top: 200px;
  right: 150px;
  width: 600px;
}

/* Main Container Styles */
.cs-main-container {
  border: none;
  border-radius: 10px;
  background-color: #181818;
}

/* Message List Styles */
.cs-message-list {
  background-color: #181818;
}

/* Message Content Styles */
.cs-message__content {
  margin-top: 15px;
  background-color: #242424;
  color: rgb(245, 242, 242);
}

.cs-message--outgoing .cs-message__content {
  color: rgba(255, 255, 255, 0.87);
  background-color: #5E5EE1;
  border-radius: 0.7em;
}

.cs-message--incoming .cs-message__content {
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  border-radius: 0.7em;
}

/* Chat Container Styles */
.cs-chat-container {
  background-color: #242424;
}

/* Message Input Styles */
.cs-chat-container .cs-message-input {
  background-color: #777474;
  border: none;
}

/* Content Editor Wrapper Styles */
.cs-message-input__content-editor-wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: #adacac;
  margin: 0;
  margin-left: 10px;
  padding: 0.6em 0.9em;
  border-radius: 0.7em;
  flex-grow: 1;
}

/* Placeholder Styles */
.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: rgba(255, 255, 255, 0.7);
}

/* Content Editor Styles */
.cs-message-input__content-editor {
  background-color: #adacac;
  color: white;
  font-weight: 400;
}

/* Attachment Button Styles */
.cs-button--attachment {
  display: none;
}

/* Send Button Styles */
.cs-button--send {
  color: #ffffff;
  background-color: transparent;
}

.cs-button--send::after {
  content: "\53D1\9001";
  margin-left: 5px;
}

/* Typing Indicator Styles */
.cs-message-list .cs-typing-indicator {
  background-color: #181818;
  padding: 0.5em 0.5em;
  font-size: 1em;
  height: 1.25em;
  line-height: 1.25em;
}

/* Button Container Styles */
.button-container {
  display: flex;
  justify-content: flex-end;
}

/* API Button Styles */
.api-button {
  padding: 0.5rem 1rem;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.api-button.med {
  background: #1D2CF3;
}

.api-button.dental {
  background: #38c68b;
}

.api-button.pet {
  background: #E43292;
}

/* Media Queries */

@media (max-width: 1440px) {
  /* Styles for MacBook */
  .chatui {
    top: 155px;
    right: 100px;
    width: 600px;
  }
}

@media (max-width: 1299px) {
  /* Styles for iPad */
  .chatui {
    top: 100px;
    right: 100px;
    width: 500px;
  }
  .chatComponent{
    height: 380px !important;
  }

}

@media (max-width: 1000px) {
  /* Styles for iPad mini */
  .chatui {
    position: absolute;
    top: 100px;
    left: 40px;
    width: 90%;
  }
  
  /* Center Button Container on iPhone */
  .button-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  /* Styles for phone */
  .chatui {
    position: absolute;
    top: 80px;
    left: 18px;
    width: 90%;
  }
}

/* Footer.css */
.footer {
  color: white;
  background-color: #494646;
  width: 100%;
  padding: 0.6rem 0;
  position: absolute; /* Change position to absolute */
  bottom: 0;
  z-index: 1;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 100%;
}

.contact-info,
.rights-info {
  font-size: 14px;
}

.rights-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 1000px) {
  .footer {
    /* padding: 0.8rem; */
    position: relative;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .rights-info {
    width: 100%;
    align-items: center;
  }

  .contact-info{
    margin-bottom: 5px;
  }
  .copyright-info {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

/* Admin.css

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.adminh1 {
    color: #333;
}

.adminbutton {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 80px;
}

.adminbutton:hover {
    background-color: #292929;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input[type="text"], input[type="password"] {
    width: 90%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

pre {
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
    overflow-x: scroll;
}

.getsurvey{
    margin-left: 45px;
}

.notification {
    background: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
  }
  
 */


   .container {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
}

.adminh1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.adminbutton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.adminbutton:hover {
    background-color: #292929;
}

input[type="text"],
input[type="password"] {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 90%;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.notification {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}

/* Add styles for the data sections */
.data-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.data-section h2 {
    margin-top: 0;
}


 .App {
  position: relative;
  z-index: 1;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 1;
  overflow-y: auto;
  padding-bottom: 100px; /* Ensure space for the footer */
}

.footer {
  position: fixed;
  bottom: 0;
  /* width: 100%; */
}

@media (max-width: 1000px) {
  .footer {
    position: absolute;
  }
}

.error-notification {
    background-color: #FF4136; /* Red background for error messages */
    color: white; /* Text color for error messages */
    padding: 12px 16px; /* Padding around the content */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Margin at the bottom */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    transition: opacity 0.3s ease-in-out;
    opacity: 0.95;
  }
  
  .success-notification {
    background-color: #2ECC40; /* Green background for success messages */
    color: white; /* Text color for success messages */
    padding: 12px 16px; /* Padding around the content */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Margin at the bottom */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    transition: opacity 0.3s ease-in-out;
    opacity: 0.95;
  }
  
/* Base Styles */
.navbar {
  display: flex;
  color: white;
  z-index: 2;
}

.navbar-container {
  display: flex;
  gap: 2rem; 
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #7E77C3;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 25px;
}

.button-container {
  display: flex; 
  align-items: center;
}

/* Styling for the dropdown container */
.dropdown-container {
  position: relative;
  display: inline-block;
  display: none;
}

/* Styling for the dropdown button */
.dropdown-button {
  background-color: #7E77C3;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

/* Styling for the dropdown box */
.dropdown-box {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #191A23;
  border: 2px solid #272835;
  width: 150px;
  height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none;
  z-index: 999;
}

/* Styling for individual rows */
.dropdown-box span {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

/* Center-align the button */
.dropdown-box button.nav-button {
  display: block;
  margin: 0 auto;
}

/* Styling for the dropdown box content */
.dropdown-box span.user-name {
  font-weight: bold;
}

.nav-button.dropdown-button {
  background-color: transparent;
}

.nav-button.dropdown-button span img {
  margin-right: 10px;
}

.nav-button.dropdown-button span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Show the dropdown content when the dropdown button is clicked */
.dropdown-container:hover .dropdown-box {
  display: block;
}

.nav-button.signout{
  margin-top: 15px !important;
  font-size: 11px;
  padding: 2px 8px;
}


@media (max-width: 700px) {
  /* styles for smaller screens (e.g., mobile) */
    .navbar-container {
      padding: 1rem;
  }
  .logo-container img {
    width: 70px;
  }

  .logo-container h3 {
    font-size: 12px;
  }

  .nav-button {
    font-size: 12px;
    padding: 2px 10px;
  }
}


@media (max-width: 425px) {
  .nav-button {
    font-size: 10px;
    padding: 1px 4px;
  }
  .navbar-container {
    gap: 1rem;
    }
  .profile{
    width: 20px;
  }
  .nav-button.signout{
    margin-top: 8px !important;
    font-size: 8px;
    padding: 0.5px 3px;
  }
  .dropdown-box {
    width: 80px;
    height: 50px;
  }
  .dropdown-box span {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
  }
}
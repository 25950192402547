/* Overlay Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(39, 40, 53, 0.8);
  backdrop-filter: blur(0.3px);
  z-index: 998;
}

/* Popup Container Styles */
.popup {
  box-sizing: border-box;
  position: fixed;
  width: 450px;
  height: 470px;
  left: calc(50% - 420px / 2);
  top: calc(50% - 470px / 2);
  z-index: 999;
}

/* Popup Content Styles */
.popup-content {
  background-color: #191a23;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Active Popup Styles (If needed) */
.popup.active {
  display: flex;
}

/* Typography */
h2 {
  text-align: center;
  font-size: 30px;
}

/* Form Input Styles */
label {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #272835;
  background-color: #191a23;
  border-radius: 3px;
  color: white;
  outline: 0;
}

/* Hover Styles for Input and Textarea */
input:hover,
textarea:hover {
  border-color: #4e497d;
}

/* Submit Button Styles */
.submit_button {
  background-color: #4e497d;
  color: #181819;
  font-size: 15px;
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

/* Close Button Styles */
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #7e77c3;
  background: rgb(26, 26, 34);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 18px;
}

/* Media Query for Smaller Screens (iPhone) */
@media (max-width: 425px) {
  .popup {
    width: 320px;
    height: 470px;
    left: 50%;
    top: calc(50% - 500px/2);
    transform: translateX(-50%);
    z-index: 999;
  }
}

/* Base Styles */
.popup {
  box-sizing: border-box;
  position: fixed;
  top: calc(50% - 520px/2);
  z-index: 999;
}

.popup-content {
  color: white;
  background-color: #191A23;
  border: 2px solid #272835;
}

select {
  box-sizing: border-box;
  appearance: none;
  margin-left: 5px;
}


/* Form Elements */
.react-tel-input .form-control {
  margin-top: 0 !important;
  height: 40px !important;
  width: 100% !important;
}

/* Verification Code Input */
.verifi_text {
  margin-top: 10px;
}

.code-input {
  display: flex;
  align-items: center;
}

.veri_button {
  background-color: #4e497d;
  color: #fefeff;
  font-size: 15px;
  border: none;
  padding: 8px 28px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  white-space: nowrap;
  margin-bottom: 17px;
}

/* Submit Button */
.submit_button {
  background-color: #4e497d;
  color: #fefeff;
  font-weight: bold;
  font-size: 18px;
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Password Toggle Button */
.password-toggle {
  position: absolute;
  background: none;
  border: none;
  top: 218px;
  right: 25px;
}

.open_eye, .closed_eye {
  width: 20px;
  fill: white;
}

/* Switch Button */
.switch-button {
  background: none;
  border: none;
  color: #7E77C3;
  cursor: pointer;
  margin-bottom: 6px;
}

/* Checkbox Container */
.checkbox-container {
  font-size: 13.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-container a {
  color: white;
}

/* Country Code Select */
.country-list {
  color: rgb(255, 255, 255);
}

#countryCode {
  font-size: 15px;
  position: absolute;
  top: 143px;
  padding-left: 5px;
  left: 23px;
  border: none;
  background-color: #191A23;
  color: white;
}

.captchaimage{
  margin-left: 10px;
  border-radius: 10px;
  width: 90px;
  margin-bottom: 15px;
}

.refresh_btn{
  margin-bottom: 15px;
  margin-left: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.refresh{
 width: 10px;
 height: 10px;
}
/* Custom Input */
.custom-input {
  /* padding-left: 57px; */
  width: 95%;
  background-color: #191A23;
  border: 1px solid #272835;
  color: white;
}

/* Error Message */
.error-message {
  font-size: 13px;
  color: #f33636;
}

.grayed-button {
  background-color: gray; /* Change the background color to gray */
  color: white; /* Change the text color for better visibility */
  cursor: not-allowed; /* Change the cursor style to indicate it's disabled */
}


/* Responsive Styles */
/* @media (max-width: 425px) {
  .popup {
    top: calc(50% - 300px/2);
    z-index: 999;
    margin-left: 12px;
  }

  #countryCode {
    top: 140px;
  }
  
  .custom-input {
    width: 94%;
  }
} */


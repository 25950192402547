/* Admin.css

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.adminh1 {
    color: #333;
}

.adminbutton {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 80px;
}

.adminbutton:hover {
    background-color: #292929;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input[type="text"], input[type="password"] {
    width: 90%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

pre {
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
    overflow-x: scroll;
}

.getsurvey{
    margin-left: 45px;
}

.notification {
    background: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
  }
  
 */


   .container {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
}

.adminh1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.adminbutton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.adminbutton:hover {
    background-color: #292929;
}

input[type="text"],
input[type="password"] {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 90%;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.notification {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}

/* Add styles for the data sections */
.data-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.data-section h2 {
    margin-top: 0;
}

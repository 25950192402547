/* Footer.css */
.footer {
  color: white;
  background-color: #494646;
  width: 100%;
  padding: 0.6rem 0;
  position: absolute; /* Change position to absolute */
  bottom: 0;
  z-index: 1;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 100%;
}

.contact-info,
.rights-info {
  font-size: 14px;
}

.rights-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 1000px) {
  .footer {
    /* padding: 0.8rem; */
    position: relative;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .rights-info {
    width: 100%;
    align-items: center;
  }

  .contact-info{
    margin-bottom: 5px;
  }
  .copyright-info {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

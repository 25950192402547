/* Base Styles */
.hero-container {
  padding: 1.5rem;
  color: white;
}

.hero-text {
  margin-bottom: 30px;
}

.hero-text h1 {
  padding-top: 60px;
  font-size: 45px;
}

.subheading {
  margin-bottom: -10px;
  font-size: 25px;
}

.text{
  margin-top: 20px !important;
}
.example {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hero-button {
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffffff;
  margin-top: 25px;
  background: none;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 1440px) {
  /* styles for macbook */
  .hero-text h1 {
    padding-top: 15px;
    font-size: 35px;
  }
}



@media (max-width: 1299px) {
  /* styles for ipad */
  .hero-container {
    margin-top: -40px;
  }
  .hero-text h1 {
    font-size: 32px;
  }
  .subheading {
    font-size: 18px;
  }

  .hero-examples {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  /* styles for iphone */
  .hero-container {
    margin-top: 430px;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center; /* Center text within the container */
  }

  .hero-text h1 {
    font-size: 32px;
  }
  .subheading {
    font-size: 18px;
  }

  .hero-examples {
    font-size: 14px;
  }

  .hero-button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  /* Styles for phone */
  .hero-text h1 {
    font-size: 22px;
  }

  .subheading {
    font-size: 14px;
  }

  .hero-examples {
    font-size: 13px;
  }
}
